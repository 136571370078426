import React, { useState, useEffect, useContext, useMemo } from 'react';
import { object, array, string, func, number } from 'prop-types';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { globalHistory as history } from '@reach/router';

import LangContext from '@contexts';
import Arrow from './image/arrow.inline.svg';
import { langPath } from '@helpers';
import style from './NewMenuItem.module.scss';

const NewMenuItem = ({ primary, items, activeMenu, handleActiveMenu, index }) => {
    const [activeImage, setActiveImage] = useState(0);
    const [image, setImage] = useState(items[activeImage]?.image);
    const [isLinkActive, setIsLinkActive] = useState(false);
    const { location } = history;
    const title = primary?.title?.richText[0]?.text;
    const currentLang = useContext(LangContext);
    const [visibleItems, setVisibleItems] = useState(4);
    const [isExpanded, setIsExpanded] = useState({});
    const [scroll, setScroll] = useState(false);

    const handleMouseEnter = (id) => {
        setActiveImage(id);
    };

    const handleClick = () => {
        if (document.querySelector('html').classList.contains('fixed')) {
            document.querySelector('html').classList.remove('fixed');
        }
    };

    useEffect(() => {
        setImage(items[activeImage]?.image);
    }, [activeImage]);

    const classItem = classnames({
        [style.item]: true,
        [style[`${index === 1 ? 'Features' : title}`]]: true,
        [style.open]: activeMenu === title,
        [style.linkActive]: isLinkActive,
    });

    const groupedItems = useMemo(() => {
        return items?.reduce((acc, item) => {
            const countryName = item.allcountry.richText[0]?.text;
            if (!acc[countryName]) {
                acc[countryName] = [];
            }
            acc[countryName]?.push(item);
            return acc;
        }, {});
    }, [items]);

    // console.log(groupedItems, "---groupedItems")

    // const handleViewAllClick = (countryname) => {
    //     setVisibleItems((prev) => ({
    //         ...prev,
    //         [countryname]: groupedItems[countryname].length,
    //     }));
    // };
    const handleViewAllClick = (countryname) => {
        setVisibleItems((prev) => ({
            ...prev,
            [countryname]: groupedItems[countryname]?.length,
        }));
        setIsExpanded((prev) => ({
            ...prev,
            [countryname]: true,
        }));
        setScroll(true);
    };

    const handleViewLessClick = (countryname) => {
        setVisibleItems((prev) => ({
            ...prev,
            [countryname]: 4,
        }));
        setIsExpanded((prev) => ({
            ...prev,
            [countryname]: false,
        }));
        setScroll(false);

    };

    // console.log(isExpanded, "isExpanded")
    // console.log(visibleItems, "visibleItems")


    return (
        <div
            className={classItem}
            onClick={() => handleActiveMenu(title)}
            onMouseLeave={() => handleActiveMenu('')}
        >
            {title}
            <Arrow />
            <div className={scroll ? style.ScrollSubmenu : style.submenu}>
                {/* <div className={style.submenu}> */}
                <div className={style.container}>
                    <div className={style.list}>
                        {Object.entries(groupedItems)?.map(([countryname, countryItems]) => {
                            const visibleNewItems = visibleItems?.[countryname] || 4;
                            const isExpandedCountry = isExpanded?.[countryname];
                            return (
                                <div key={countryname} className={style.countrySection}>
                                    <div className={style.countryname}>{countryname}</div>
                                    {countryItems?.slice(0, visibleNewItems)?.map((item, index) => {
                                        const text = item.name.richText[0]?.text;
                                        // console.log(text, "text")
                                        let link;
                                        const isNew = item.isnew;
                                        // console.log(item, "Citem")

                                        if (item?.externallink?.url) {
                                            link = item?.externallink?.url;
                                            location?.pathname === link && setIsLinkActive(true);
                                            return (
                                                <div key={text} className={style.countryData}>
                                                    <a
                                                        href={link}
                                                        className={style.link}
                                                        activeClassName={style.linkActive}
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onClick={handleClick}
                                                    >
                                                        {text} {isNew && <span className={style.badge}>New</span>}
                                                    </a>
                                                </div>
                                            );
                                        } else {
                                            link = langPath(currentLang) + '/' + item?.link?.richText[0]?.text?.toLowerCase();
                                            location.pathname === link && setIsLinkActive(true);
                                            return (
                                                <Link
                                                    to={link}
                                                    key={text}
                                                    className={style.link}
                                                    activeClassName={style.linkActive}
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onClick={handleClick}
                                                >
                                                    {text} {isNew && <span className={style.badge}>New</span>}
                                                </Link>
                                            );
                                        }
                                    })}
                                    {countryItems?.length > 4 && (
                                        <>
                                            {isExpandedCountry ? (
                                                <a
                                                    className={style.viewLessButton}
                                                    onClick={() => handleViewLessClick(countryname)}
                                                >
                                                    Show Less
                                                </a>
                                            ) : (
                                                <a
                                                    className={style.viewAllButton}
                                                    onClick={() => handleViewAllClick(countryname)}
                                                >
                                                    View All
                                                </a>
                                            )}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {/* <div className={style.imageWrapper}>
                        <img src={image?.url} alt={image?.alt} loading="lazy" />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

NewMenuItem.propTypes = {
    primary: object,
    items: array,
    activeMenu: string,
    handleActiveMenu: func,
    location: string,
    index: number,
};

export default NewMenuItem;
